body {
    background-color: #E2E2DF;
}

#root {
    width: 100%;
}

.page {
    padding: 10px;
}

.pageContent {
    background-color: #ffffff;
    padding: 10px;
}

.pageHeader {
    border-bottom: 1px solid #D2D2CF;
    margin-bottom: 10px;
    padding-bottom: 10px;
}

.pageFooter {
    border-top: 1px solid #D2D2CF;
    margin-top: 10px;
    padding-top: 10px;
}

.card {
    border: 1px solid black;
    border-radius: 10px;
    padding: 10px;
    max-width: 900px;
    background-color: #FAEDCB;
    margin: 10px 0;
}

.cardHeading {
    font-size: 20px;
    font-weight: bold;
    color: black;
    margin-bottom: 10px;
}

.cardFront, .cardBack {
    border-radius: 10px;
    margin: 1px;
    background-color: #C9E4DE;
    color: black;
    font-weight: bold;
    min-height: 100px;
    max-height: 500px;
    overflow-y: scroll;
    display: grid;
}

.cardFront.editor, .cardBack.editor {
    max-height: none;
    overflow-y: auto;
    display: initial;
}

.cardFront .inner, .cardBack .inner {
    margin: auto;
}

.cardBack {
    background-color: #C6DEF1;
}

.cardFront .inner img, .cardBack .inner img {
    max-width: 100%;
}

.cardFooter {
    margin: 10px 0;
    text-align: center;
}

a.imageLink, .pixmapText {
    line-height: 32px;
    vertical-align: top;
    margin-left: 10px;
}

.poolViewPagination {
    margin-bottom: 10px;
}

.pixmapButton {
    padding: 5px;
}

.pixmapLink {
    text-decoration: none;
}

.pixmapGroup .pixmapButton {
    margin: 0 5px;
}

.cardFooter br {
    margin-bottom: 22px;
}

/*list-style: none;list-style-type: none;list-style-position: unset;padding: 0;text-decoration: none;*/
/* https://coolors.co/e2e2df-d2d2cf-e2cfc4-f7d9c4-faedcb-c9e4de-c6def1-dbcdf0-f2c6de-f9c6c9 */

.toggleButton {
    background-color: #F9C6C9;
    padding: 10px;
    margin: 10px 10px 10px 0;
    line-height: 32px;
    min-width: 100px;
    display: inline-block;
    text-align: center;
    border: 1px solid transparent;
}

.toggleButton.pushed {
    border: 1px solid black;
    background-color: #C9E4DE;
    box-shadow: 0 0 2px 2px #C9E4DE;
}

.listLabelSuccessful {
    list-style-image: url("/icons/check.svg");
}

.listLabelKnown {
    list-style-image: url("/icons/cancel.svg");
}

.listLabelUnknown {
    list-style-image: url("/icons/question.svg");
}

.error {
    font-weight: bold;
    font-size: 20px;
    color: red;
    line-height: 32px;
}

.loginBanner {
    position: relative;
    background-color: #C6DEF1;
    width: 100%;
    height: 64px;
}
.loginBanner .inner {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0px;
}

.loginBanner > span {
    line-height: 64px;
    vertical-align: middle;
}

/*.ql-container {*/
/*    overflow-y: scroll;*/
/*    max-height: 500px;*/
/*}*/

.ql-editor {
    overflow-y: scroll;
    max-height: 500px;
}

.remainingCards {
    float: right;
    line-height: 50px;
}